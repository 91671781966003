import { createPortal } from 'react-dom'
import { createRoot } from 'react-dom/client'

import { GlobalQueryClientProvider } from '@components/v2/GlobalQueryClientProvider'

import type { ReactNode } from 'react'

const mountRegistry = new Set<Element>()
export function mountReact(children: ReactNode, selector: Element) {
  if (mountRegistry.has(selector)) {
    return
  }

  mountRegistry.add(selector)

  const parent = selector.parentNode
  if (!parent) {
    return
  }
  const portal = createPortal(
    <GlobalQueryClientProvider>{children}</GlobalQueryClientProvider>,
    parent as Element,
  )
  const root = createRoot(document.createElement('div'))
  root.render(portal)
  return root
}

export function mountRegularReact(
  Children: (...arguments_: any[]) => JSX.Element,
  selector: Element,
) {
  if (!selector) {
    return
  }

  if (mountRegistry.has(selector)) {
    return
  }

  mountRegistry.add(selector)

  const parent = selector

  // @ts-ignore

  // @ts-ignore
  const props = Object.fromEntries(
    // @ts-ignore
    Object.keys(selector.dataset).map((key) =>
      // @ts-ignore
      [key, selector.dataset[key]],
    ),
  )

  const portal = createPortal(
    <GlobalQueryClientProvider>
      <Children {...props} />
    </GlobalQueryClientProvider>,
    parent,
  )
  const root = createRoot(document.createElement('div'))
  root.render(portal)
  return root
}
